@import "../../../node_modules/bootstrap/scss/bootstrap";

.sign-page {
  &-spacer {
    padding-top: 100px;
    padding-bottom: 100px;
    @media screen and (max-width: map-get($grid-breakpoints, "sm")) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &-bg {
    background-image: url("/assets/log-in-background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}
