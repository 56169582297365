$foo: 1.9rem;

.calendar {
}

.month span {
  //min-width: 6rem;
  //text-align: right;
  font-family: "Lobster", cursive;
  padding-right: 1rem;
}

.day-number {
  display: inline-block;

  width: $foo;
  height: $foo;
  line-height: $foo;

  font-size: 0.9rem;
  text-align: center;

  color: #eee;

  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

  margin: 1px;
  cursor: pointer;
}

.avatar {
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.7);
  border-radius: 7px;
  width: 30px;
  height: 30px;

  &.dropdown-toggle::after {
    vertical-align: 0;
  }

  path {
    stroke: rgba(255, 255, 255, 0.7);
    stroke-width: 2px;
  }
}
